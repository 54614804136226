<template>
    <v-expansion-panel
    >
      <v-expansion-panel-header>
        {{ todo ? 'Tareas' : 'Tareas completadas'}}
      </v-expansion-panel-header>
      <v-expansion-panel-content>
        <div
          v-if="todo && !editedItem"
          style="padding: 0 0 10px 0;"
        >
          <v-btn 
            @click="handleAdd"
            color="primary"
            block
          >
            {{ $t('common.add', locale) }}
          </v-btn>
        </div>
        <v-select
          v-if="todo && editedItem"
          v-model="editedItem.task"
          :items="taskTypes"
          label="Tarea"
          outlined
          dense
          hide-details
          item-text="name"
          item-value="id"
        />
        <action-buttons
          v-if="editedItem"
          :onSaveItem="handleSave"
          :onClose="() => { editedItem = null}"
          :locale="locale"
        />
      <v-simple-table>
    <template v-slot:default>
      <thead>
        <tr>
          <th class="text-left">
            Tarea
          </th>
          <th 
            v-if="!todo"
            class="text-left"
          >
            LMU
          </th>
          <th 
            class="text-right"
          >
          </th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="item in tasks"
          :key="item.ID"
        >
          <td>
            {{ item.TaskName }}
          </td>
          <td
            v-if="!todo"
          >
            {{ item.RD ? item.RD.split('.').shift().split('T').join(' ') : '' }}
          </td>
          <td
            class="text-right"
          >
            <delete-item
              icon="mdi-delete"
              :locale="locale"
              :id="item.ID"
              :onDeleteItem="handleDeleteTask"
            />
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
        <!--v-layout wrap>
          <v-flex xs12
            v-for="item of tasks"
            :key="item.ID"
            style="border: 1px solid #CCCCCC; padding: 10px;"
          >
            <v-layout wrap>
              <v-flex xs6
              >
                {{ item.TaskName}}
              </v-flex>
              <v-flex xs6
              >
                {{ item.RD ? item.RD.split('.').shift().split('T').join(' ') : '' }}
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout-->
      </v-expansion-panel-content>
    </v-expansion-panel>
</template>
<script>
const defaultItem = {
  id: null,
  task: '78cd61b8-be29-45c7-b703-42df91bd1c98',
}
import api from '@/services/api'
import ActionButtons from '@/components/ActionButtons'
import DeleteItem from '@/components/DeleteItem'
export default {
  components: {
    ActionButtons,
    DeleteItem,
  },
  props: {
    totemID: {
      type: String,
      required: true,
    },
    todo: {
      type: Boolean,
      default: true,
    },
    locale: {
      type: String,
      required: true,
    },
    panel: {
      type: Number,
      default: null,
    },
    taskTypes: {
      type: Array,
      default: null,
    },
  },
  data: () => ({
    //panel: null,
    tasks: null,
    editedItem: null,
  }),
  computed: {
    environment () {
      return 'players'
    },
    /*
    taskTypes () {
      return [
        {
          id: '182833e0-8998-4f49-91c0-7c963a5010d7',
          name: 'Capturar pantalla',
        },
        {
          id: '78cd61b8-be29-45c7-b703-42df91bd1c98',
          name: 'Reiniciar',
        },
        {
          id: 'f50e1a30-27ac-4d29-8e7d-a93e2e1c7e4e',
          name: 'Apagar',
        },
        {
          id: 'aa07a52c-3d9b-48b5-af67-2835f45b208e',
          name: 'Cerrar navegador',
        },
        {
          id: 'bf50c2cc-307c-4170-9d0c-b3ab457c90b6',
          name: 'Reset navegador',
        },  
        {
          id: 'eca095d2-3eda-4a9f-9785-60735330cafc',
          name: 'Eliminar config',
        },
        {
          id: 'a497735a-c68d-4e98-a361-ac1de79cceed',
          name: 'Eliminar config | apagar ',
        },  
        {
          id: '05c913a3-47bd-4263-b2bd-125519f49005',
          name: 'Actualizar APP',
        },
      ]
    }*/
  },
  watch: {
    panel () {
      if (this.panel !== null && !this.tasks) this.handleGetList()
    },
    totemID () {
      this.handleGetList()
    },
  },
  mounted () {
    this.handleGetAllTask()
    //this.handleGetList()
  },
  methods: {
    handleGetList () {
      api.getAllWithoutLimit (this.environment, `v1/private/totems/${this.totemID}/${this.todo ? 'todo-task' : 'finished-task'}/`)
        .then(response => {
          this.tasks = response.map(item => {
            item.TaskName = this.taskTypes.filter(x => x.id === item.Task).map(i => i.name).shift()
            return item
          })
        })
    },
    handleAdd () {
      this.editedItem = JSON.parse(JSON.stringify(defaultItem))
    },
    handleSave () {
      const url = `v1/private/totems/${this.totemID}/tasks/`
      api.addItem (this.environment, url, this.editedItem)
        .then(() => {
          this.handleGetList()
          this.editedItem = null
        })
    },
    handleDeleteTask (v) {
      const url = `v1/private/totems/${this.totemID}/tasks/`
      api.deleteItem (this.environment, url, v)
        .then(() => {
          this.handleGetList()
        })
    },
    handleGetAllTask () {
      api.getAllWithoutLimit ('players', 'v1/internal/constants/totem-task')
        .then(response => {
          this.taskTypes = response
        })
    },
  },
}
</script>
