<template>
  <v-layout wrap v-if="editedItem">
    <h2 style="margin-bottom: 20px;">
      <v-icon color="black" style="margin-right: 10px;">mdi-gesture-tap-hold</v-icon>{{ editedID === 'new' ? $t('common.add', locale) : $t('common.edit', locale) }} {{$t('players.totems', locale).toLowerCase()}}
    </h2>
    <v-flex xs12 v-if="!editedItem.ID">
      <qrcode-stream v-if="isMobile" @decode="onDecode" />
      <v-text-field
        v-model="editedItem.ID"
        label="ID"
        outlined
        dense
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.Name"
        label="Nombre"
        outlined
        dense
      />
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="editedItem.WorkspaceID"
        :items="workspaces"
        outlined
        dense
        item-text="Name"
        item-value="ID"
        :label="`${$t('players.client', locale)} (*)`"
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field
        v-model="editedItem.URL"
        label="URL"
        outlined
        dense
        hide-details
      />
    </v-flex>
    <v-flex xs12 style="padding-bottom: 0;">
      <v-checkbox
        v-model="editedItem.AutoScreenshot"
        label="Captura de pantalla auto"
        hide-details
      />
      <small style="color:#CCCCCC;">Se crea una captura de pantalla cada 4 horas.</small>
    </v-flex>
    <v-flex xs12 style="padding-bottom: 20px;">
      <v-checkbox
        v-model="editedItem.Maintenance"
        label="Mantenimiento"
        hide-details
      />
      <small style="color:#CCCCCC;">Mantenimiento: impide que se abra el navegador del TOTEM</small>
    </v-flex>
    <v-flex xs12 style="padding-bottom:0px;">
      <v-expansion-panels
        v-model="panelTask"
      >
        <totem-tasks
          v-if="editedItem.ID"
          :totemID="editedItem.ID"
          :todo="true"
          :locale="locale"
          :panel="panelTask"
          :taskTypes="allowedTasks"
        />
        <totem-tasks
          v-if="editedItem.ID"
          :totemID="editedItem.ID"
          :todo="false"
          :locale="locale"
          :panel="panelTask"
          :taskTypes="allowedTasks"
        />
      </v-expansion-panels>
    </v-flex>
    <v-flex xs12 style="padding: 10px 0 0 0;">
      <v-expansion-panels>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Captura de pantalla <small v-if="screenshot" style="margin-left: 20px;color:#CCCCCC">[ {{convertDateTime(screenshot.cd)}} ]</small>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <div style="padding-bottom: 20px;">
              <v-btn
                @click="handleGetScreenshot"
                color="primary"
                block
              >
                refrescar
              </v-btn>
            </div>
            <preview-view
              v-if="screenshot"
              :config="screenshot"
              :locale="locale"
              :prevHeight="180"
              :cd="convertDateTime(screenshot.cd)"
            />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
    <!--v-flex xs12>
      <v-text-field
        v-model="editedItem.Time"
        label="Time"
        outlined
        dense
        hide-details
      />
    </v-flex>
    <v-flex xs12>
      <v-autocomplete
        v-model="editedItem.DeviceOrientation"
        :items="deviceOrientations"
        outlined
        dense
        item-text="name"
        item-value="id"
        :label="$t('players.deviceOrientation', locale)"
        hide-details
      />
    </v-flex>
    <v-flex xs12 style="padding-top:20px;">
          <v-autocomplete
            v-model="editedItem.PresentationID"
            :items="presentations"
            outlined
            dense
            item-text="Name"
            item-value="ID"
            :label="$t('players.presentation', locale)"
            hide-details
          />
    </v-flex>
    <v-flex xs12 style="padding-top:20px;">
      <v-select
        :items="APKs"
        :label="$t('players.downloadAPK', locale)"
        v-model="editedItem.DownloadAPK"
        item-text="name"
        item-value="id"
        outlined
        dense
        hide-details
      />
    </v-flex-->
    <v-flex xs12>
      <action-buttons
        v-if="editedItem"
        :id="editedItem.ID"
        :onDeleteItem="handleDelete"
        :onSaveItem="handleSave"
        :onClose="onClose"
        :locale="locale"
      />
    </v-flex>
  </v-layout>
</template>
<script>
const defaultItem = {
  ID: null,
  Name: '',
  URL: null,
  //PresentationID: '140181d0-aeff-11ed-9c29-f933aac7d201',
  //Time: 15,
  Active: false,

}
import { QrcodeStream } from 'vue-qrcode-reader'
import api from '@/services/api'
import utils from '@/services/utils'
import TotemTasks from './totemTasks/Index'
import PreviewView from './Preview'
import ActionButtons from '@/components/ActionButtons'
export default {
  components: {
    ActionButtons, 
    QrcodeStream,
    TotemTasks,
    PreviewView,
  },
  props: {
    workspaceID: {
      type: String,
      required: true,
    },
    allowedTasks: {
      type: Array,
      default: null,
    },
    editedID: {
      type: String,
      required: true,
    },
    locale: {
      type: String,
      default: 'es',
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    editedItem: null,
    workspaces: [],
    panelTask: null,
    screenshot: null,
  }),
  computed: {
    isMobile () {
      return utils.isMobile()
    },
    environment () {
      return 'players'
    },
    deviceOrientations () {
      return [
        {
          id: null,
          name: 'default'
        },
        {
          id: 'portraitDown',
          name: 'portraitDown'
        },
        {
          id: 'portraitUp',
          name: 'portraitUp'
        },
        {
          id: 'landscapeLeft',
          name: 'landscapeLeft'
        },
        {
          id: 'landscapeRight',
          name: 'landscapeRight'
        },
      ]
    },
  },
  watch: {
    'editedID' () {
      this.handleGetData()
    },
    workspaceID () {
      this.handleGetData()
    },
  },
  mounted () {
    this.handleGetWorkspaces()
    this.handleGetData()
  },
  methods: {
    convertDateTime (v) {
      if (!v) return ''
      return v.replace('T', ' ').split('.').shift()
    },
    handleGetWorkspaces () {
      api.getAllWithoutLimit (this.environment, `v1/internal/users/${utils.getUser()}/workspaces`)
        .then(response => {
          this.workspaces = response
        })
    },
    async handleGetScreenshot () {
      if (!this.editedID) {
        this.editedItem = null
        return
      }
      this.screenshot = await new Promise((resolve) => api.getItem(this.environment, 'v1/private/totems/', `${this.editedID}/screenshots`)
        .then(response => {
          if (response) {
            resolve({
              width: 1920,
              height: 1080,
              url: response.Screenshot,
              cd: response.CD
            })
          } else {
            resolve(null)
          }
        })
        .catch (err => { 
          console.log(err)
          resolve(null) 
        }))
    },
    async handleGetData () {
      if (!this.editedID) {
        this.editedItem = null
        return
      }
      
      await this.handleGetScreenshot()

      if (this.editedID === 'new') this.editedItem = JSON.parse(JSON.stringify(defaultItem))
      else {
        const url = 'v1/private/totems/'
        api.getItem (this.environment, url, this.editedID)
          .then(response => {
            if (response) this.editedItem = JSON.parse(JSON.stringify(response))
          })
      }
    },
    prepareData () {
      this.item = JSON.parse(JSON.stringify(this.editedItem))
    },
    onDecode (v) {
      this.item.ID = v
    },
    handleSave () {
      if (!this.editedItem.WorkspaceID) {
        alert('El cliente es requerido')
        return
      }

      if (!this.editedItem.ID) {
        // insert
      } else {
        //update
        const url = 'v1/private/totems/'
        api.updateItem (this.environment, url, this.editedItem.ID, this.editedItem)
          .then(() => {
            this.onClose(true)
          })
      }
    },
    handleDelete () {
      const url = 'v1/private/totems/'
      api.deleteItem (this.environment, url, this.editedItem.ID)
        .then(() => {
          this.onClose(true)
        })
    },
  },
}
</script>
